








import KCard from '@/@core/components/other/KCard.vue';
import KText from '@/@core/components/typography/KText.vue';
import {
  defineComponent,
  onMounted,
  PropType
} from '@vue/composition-api';
import SingleWageForm from './SingleWageForm.vue';

export default defineComponent({
  components: { KText, SingleWageForm, KCard },
  name: 'WagesForm',
  props: {
    data: {
      type: Object as PropType<any>,
      required: true
    }
  },
  setup(props) {
    const defaultWageData = {
      type: '',
      wage: null
    };

    onMounted(() => {
      const propsData: any = props.data;
      const hasWages = propsData.wages?.length;

      if (hasWages) return;

      propsData.wages = [{ ...defaultWageData }];
    });

    return {
      defaultWageData
    };
  }
});
